export default function () {
  const expendableImg = document.querySelector('.img-expandable');
  if(!expendableImg) return;

  let currentWidth = 75;
  let currentHeight = 30;
  let minWidth = 75;
  let maxWidth = 100;
  let minHeight = 30;
  let maxHeight = 100;
  let widthStepSize = 5;
  let heightStepSize = 15;
  let scrollPos = 0;

  document.addEventListener("scroll", resizeImg);

  function resizeImg(e) {
    let direction;
    // check direction

    let newWidth = currentWidth + direction * widthStepSize;
    let newHeight = currentHeight + direction * heightStepSize;


    if(newWidth >= minWidth && newWidth <= maxWidth) {
        currentWidth = newWidth;
        expendableImg.style.width = currentWidth + 'vw';
    }

    if(newHeight >= minHeight && newHeight <= maxHeight) {
      currentHeight = newHeight;
      expendableImg.style.height = currentHeight + 'vh';
  }



    // if(direction == 'down') {
    //   expendableImg.style.width = '100vw';
    //   expendableImg.style.height = '100vh'
    // } else {
    //   expendableImg.style.width = '75vw';
    //   expendableImg.style.height = '30vh';
    // }
  }


  // with gsap : https://gsap.com/community/forums/topic/38374-scale-image-on-scroll-scroll-in-text-as-soon-as-the-image-reaches-100vw-but-keep-image-sticky/
}
