export default () => ({
  openAutocomplete: false,
  query: "",
  results: [],
  init () {
    document.addEventListener('click', this.onClick.bind(this));
  },
  async fetchResults (){
    if (this.query.length < 3) {
      this.results = [];
      return;
    }
    try {
      const source = await fetch(`/api/search?q=${this.query}`);
      const data = await source.json();
      this.results = data.data;
    } catch {
      this.results = [];
    }
  },
  onFocusIn() {
    this.openAutocomplete = true;
  },
  onFocusOut(e) {
    if (this.openAutocomplete === false) return;
    if (this.checkIfElementIsInsideElement(this.$refs.autocompleteContainer, e.relatedTarget) || e.relatedTarget === null) return;
    this.openAutocomplete = false;
  },
  onClick(e) {
    if (this.openAutocomplete === false) return;
    if (this.checkIfElementIsInsideElement(this.$refs.autocompleteContainer, e.target)) return;
    this.openAutocomplete = false;
  },
  handleKeyup(e) {
    if (e.key === "ArrowDown") {
      e.preventDefault();

      let selected = document.querySelector("li.selected");

      if(!selected) {
        let firstSelection = document.querySelector("#results li");
        firstSelection.classList.add("selected");
        firstSelection.querySelector('a').focus();
      } else {
        const selectedIsNotLast = document.querySelector("li.selected:not(:last-child)");

        if (selectedIsNotLast) {
          selectedIsNotLast.classList.remove("selected");
          let selectedNext = selectedIsNotLast.nextElementSibling;
          selectedNext.classList.add("selected");
          selectedNext.querySelector('a').focus();
        }
      }
    }

    if (e.key === "ArrowUp") {
      e.preventDefault();

      let selectedIsNotFirst = document.querySelector("li.selected:not(:nth-child(2))");

      if (selectedIsNotFirst) {
        selectedIsNotFirst.classList.remove("selected");
        let selectedPrev = selectedIsNotFirst.previousElementSibling;
        selectedPrev.classList.add("selected");
        selectedPrev.querySelector('a').focus();
      }
    }
  },
  checkIfElementIsInsideElement(sourceElement, targetElement) {
    return sourceElement.contains(targetElement) ||
      sourceElement === targetElement;
  }
});
