import '/assets/styles/main.pcss';

import { createApp } from 'vue';

import Alpine from 'alpinejs';
import LazyLoad from 'vanilla-lazyload';
import focus from '@alpinejs/focus';
import Tobii from '@midzer/tobii/dist/tobii.module.js';
import tippy from 'tippy.js';

import PrimeVue from "primevue/config";

import products from './alpine-components/products.js';
import headerMobile from './alpine-components/headerMobile.js';
import searchAutocomplete from './alpine-components/searchAutocomplete.js';

import specs from './components/specs.js';
import shop from './components/shop.js';
import dropdown from './components/dropdown.js';
import stackingCards from './components/stackingCards.js';
import carousels from './components/carousels.js';
import header from './components/header.js';
import formAddress from './components/formAddress.js';
import formVehicle from './components/formVehicle.js';
import tabToggle from './components/tabToggle.js';
import slideToggle from './components/slideToggle.js';
import siteSwitcherSelect from './components/siteSwitcherSelect.js';
import anchorNavigation from "./components/anchorNavigation";
import newsletterSubscription from "./components/newsletterSubscription";
import expendableImage from "./components/expendableImage";

import TransferKitAssistantApp from './vue-components/TransferKitAssistant/TransferKitAssistantApp.vue';
import OwnerZone from './vue-components/OwnerZone.vue';
import ProductSelector from './vue-components/ProductSelector.vue';
import SelectVehicle from './vue-components/SelectVehicle.vue';
import StoreLocator from './vue-components/StoreLocator.vue';

import storeTransferKitAssistantApp from './store/transferKitAssistantApp.js';
import productSelectorStore from './store/productSelectorStore.js';

import theme from './primevue/theme.js';

Alpine.plugin(focus);

document.addEventListener('DOMContentLoaded', () => {
  window.lazyLoadInstance = new LazyLoad({});

  Alpine.data('headerMobile', headerMobile);
  Alpine.data('searchAutocomplete', searchAutocomplete);
  Alpine.data('products', (p) => products(p));

  Alpine.start();

  specs();
  shop();
  dropdown();
  stackingCards();
  carousels();
  header();
  formAddress();
  formVehicle();
  tabToggle();
  slideToggle();
  siteSwitcherSelect();
  anchorNavigation();
  newsletterSubscription();
  expendableImage();

  tippy('[data-tippy-content]', {
    allowHTML: true,
    placement: 'top-start',
  });

  new Tobii({
    closeText: "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
      "  <path d=\"M5.07107 19.0711L19.2132 4.92893M5.07107 4.92893L19.2132 19.0711\" stroke=\"currentColor\" stroke-width=\"1.5\" stroke-linecap=\"square\"/>\n" +
      "</svg>\n",
    autoplayVideo: false,
    zoom: false,
  });

  const productSelector = document.getElementById("app-product-selector");
  if (productSelector) {
    const props = {};
    const dataset = productSelector.dataset;
    for (const d in dataset) {
      try {
        props[d] = JSON.parse(dataset[d]);
      } catch(e) {
        props[d] = dataset[d];
      }
    }
    createApp(ProductSelector, { ...props })
      .use(PrimeVue, {
        unstyled: true,
        pt: theme
      })
      .use(productSelectorStore)
      .mount('#app-product-selector');
  }

  const selectVehicle = document.getElementById("app-select-vehicle");
  if (selectVehicle) {
    const props = {};
    const dataset = selectVehicle.dataset;
    for (const d in dataset) {
      try {
        props[d] = JSON.parse(dataset[d]);
      } catch(e) {
        props[d] = dataset[d];
      }
    }
    createApp(SelectVehicle, { ...props })
      .use(PrimeVue, {
        unstyled: true,
        pt: theme
      })
      .use(productSelectorStore)
      .mount('#app-select-vehicle');

    const elBtnsVehicles = document.querySelectorAll('.btn-select-vehicle');

    elBtnsVehicles.forEach(element => {

      element.addEventListener('click', () => {
        const event = new CustomEvent("SelectVehicle:showModal", { detail: { buttonElement: element } });
        document.dispatchEvent(event);
      });

    });
  }

  const TransferKitsAssistant = document.getElementById('app-transfer-kit-assistant');
  if (TransferKitsAssistant) {
    const props = {};
    const dataset = TransferKitsAssistant.dataset;
    for (const d in dataset) {
      try {
        props[d] = JSON.parse(dataset[d]);
      } catch(e) {
        console.log("error", e);
        props[d] = dataset[d];
      }
    }
    createApp(TransferKitAssistantApp, { ...props })
      .use(PrimeVue, {
        unstyled: true,
        pt: theme
      })
      .use(storeTransferKitAssistantApp)
      .mount('#app-transfer-kit-assistant');

    const elButtonsAssistant = document.querySelectorAll('.button-transfer-assistant');

    elButtonsAssistant.forEach(element => {

      element.addEventListener('click', () => {
        const event = new CustomEvent("TransferKitAssistant:showModal", { detail: { buttonElement: element } });
        document.dispatchEvent(event);
      });

    });
  }

  const ownerZone = document.getElementById('app-owner-zone');
  if (ownerZone) {
    const props = {};
    const dataset = ownerZone.dataset;
    for (const d in dataset) {
      try {
        props[d] = JSON.parse(dataset[d]);
      } catch(e) {
        props[d] = dataset[d];
      }
    }
    createApp(OwnerZone, { ...props })
      .use(PrimeVue, {
        unstyled: true,
        pt: theme
      })
      .mount('#app-owner-zone');

    const elButtonsOwnerZone = document.querySelectorAll('.button-owner-zone');

    elButtonsOwnerZone.forEach(element => {
      element.addEventListener('click', () => {
        const event = new CustomEvent("OwnerZone:showModal", { detail: { buttonElement: element } });
        document.dispatchEvent(event);
      });
    });
  }

  const storeLocator = document.getElementById('app-store-locator');
  if (storeLocator) {
    const props = {};
    const dataset = storeLocator.dataset;
    for (const d in dataset) {
      try {
        props[d] = JSON.parse(dataset[d]);
      } catch(e) {
        props[d] = dataset[d];
      }
    }
    createApp(StoreLocator, { ...props })
      .use(PrimeVue, {
        unstyled: true,
        pt: theme
      })
      .mount('#app-store-locator');
  }
});

// scroll to element on page load
window.addEventListener('pageshow', () => {
  const elId = window.location.hash;
  if (elId.length > 1){
    const el = document.getElementById(elId.substring(1));
    if (el) el.scrollIntoView({ behavior: "instant" });
  }
});
