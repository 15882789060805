<template>
  <div class="step-2">
    <navigation-previous-step/>
    <div id="panel-title" class="h4 mt-10">{{ getGlobals.secondPanel.title }}</div>
    <img class="my-8" :src="getGlobals.secondPanel.imgUrl" :alt="getGlobals.secondPanel.imgAlt">
    <div class="wysiwyg wysiwyg-white text-sm" v-html="getGlobals.secondPanel.text"></div>
    <div class="mt-10 bg-gray-dark p-6">
      <Select
        class="mb-6"
        v-model="fromBrandValue"
        show-clear
        :placeholder="getLabels.brand"
        name="currentBrand"
        :options="getBrandsData"
        :disabled="getBrandsData.length === 0"
        @update:modelValue="onChangeSelectFromBrand"
      >
      </Select>
      <Select
        class="mb-6"
        v-model="fromYearValue"
        show-clear
        :placeholder="getLabels.year"
        name="currentYear"
        :options="getFromYearsData"
        :disabled="getFromYearsData.length === 0"
        @update:modelValue="onChangeSelectFromYear"
      >
      </Select>
      <Select
        class="mb-10"
        v-model="fromModelValue"
        show-clear
        :placeholder="getLabels.model"
        name="currentModel"
        :options="getFromModelsData"
        :disabled="getFromModelsData.length === 0"
        @update:modelValue="onChangeSelectFromModel"
      >
      </Select>
      <navigation-next-step/>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Select from 'primevue/select';
import { createNamespacedHelpers } from 'vuex';
import NavigationNextStep from './NavigationNextStep.vue';
import NavigationPreviousStep from './NavigationPreviousStep.vue';

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('transferKitAssistant');

export default {
  components: {
    Select,
    NavigationNextStep,
    NavigationPreviousStep
  },
  data: () => ({}),
  computed: {
    ...mapGetters([
      'getLabels',
      'getGlobals',
      'getSerialNumberSelected',
      'getFormStepVehicleFromValidation',
      'getVehicleType',
      'getCamsoModel',

      'getFromBrandValue',
      'getBrandsData',

      'getFromModelValue',
      'getFromModelsData',

      'getFromYearValue',
      'getFromYearsData'
    ]),
    fromBrandValue: {
      get () {
        return this.getFromBrandValue;
      },
      set (value) {
        this.setFromBrandValue(value);
      }
    },
    fromModelValue: {
      get () {
        return this.getFromModelValue;
      },
      set (value) {
        this.setFromModelValue(value);
      }
    },
    fromYearValue: {
      get () {
        return this.getFromYearValue;
      },
      set (value) {
        this.setFromYearValue(value);
      }
    }
  },
  methods: {
    ...mapMutations([
      'setCurrentStep',
      'setFormStepVehicleFromValidation',
      'setSerialNumberSelected',

      'setFromBrandValue',

      'setFromModelValue',
      'setFromModelsData',

      'setFromYearValue',
      'setFromYearsData',
    ]),
    ...mapActions(['updateBrandsFromApi']),
    setFormValidation () {
      this.setFormStepVehicleFromValidation(!!this.getFromBrandValue && !!this.getFromYearValue && !!this.getFromModelValue);
    },
    async onChangeSelectFromBrand (value) {
      this.setFormValidation();
      const url = new URL(`${window.location.origin}/actions/website/products/vehicles`);

      url.searchParams.set('brand', value);
      url.searchParams.set('vehicleType', this.getVehicleType);
      url.searchParams.set('camsoModel', this.getCamsoModel);

      try {
        const res = await axios.get(url);

        if (res.data.lookups.models.length === 0) {
          console.log('model No Data');
        }

        this.setFromYearValue('');
        this.setFromYearsData(res.data.lookups.years);

        this.setFromModelValue('');
        this.setFromModelsData(res.data.lookups.models);
      } catch (error) {
        console.log('error:', error);
      }
    },

    async onChangeSelectFromYear (value) {
      this.setFormValidation();
      const url = new URL(`${window.location.origin}/actions/website/products/vehicles`);

      url.searchParams.set('brand', this.getFromBrandValue);
      url.searchParams.set('year', value);
      url.searchParams.set('vehicleType', this.getVehicleType);
      url.searchParams.set('camsoModel', this.getCamsoModel);

      try {
        const res = await axios.get(url);

        if (res.data.lookups.models.length === 0) {
          console.log('years No Data');
        }

        this.setFromModelsData(res.data.lookups.models);

        if (!res.data.lookups.models.includes(this.getFromModelValue)) {
          this.setFromModelValue('');
        }
      } catch (error) {
        console.log('error:', error);
      }
    },

    async onChangeSelectFromModel (value) {
      this.setFormValidation();
      const url = new URL(`${window.location.origin}/actions/website/products/vehicles`);

      url.searchParams.set('brand', this.getFromBrandValue);
      url.searchParams.set('model', value);
      url.searchParams.set('vehicleType', this.getVehicleType);
      url.searchParams.set('camsoModel', this.getCamsoModel);

      try {
        const res = await axios.get(url);

        if (res.data.lookups.years.length === 0) {
          console.log('years No Data');
        }

        this.setFromYearsData(res.data.lookups.years);

        if (!res.data.lookups.years.includes(this.getFromYearValue)) {
          this.setFromYearValue('');
        }
      } catch (error) {
        console.log('error:', error);
      }
    }
  },
  created () {
    this.updateBrandsFromApi({ paramVehicleType: this.getVehicleType });
  },
};
</script>
